import { differenceInYears, parse } from 'date-fns'
import { declensionByNum } from 'utils'
import { YEAR_CASES } from 'constants/wordCases'
import { MAX_PATIENT_AGE } from 'components/common/AppointmentPage/constants'

/**
 * @callback Rule
 * @param { any } val
 *
 * @return { true | string }
 * */
/** @typedef { Rule } RequiredRule */
/** @typedef { Rule } AgeLimitRule */

/**
 * @param { { min?: number, max?: number }[] } rangeAgeLimits
 * @param { { isServices: boolean } } options
 *
 * @return { { required: RequiredRule, ageLimit: AgeLimitRule } }
 * */
const getBirthDayValidationRulesAllSpecialties = (rangeAgeLimits = [], options = {}) => {
  const { isServices = false } = options

  /** @type { RequiredRule } */
  const required = val => !!val || 'Укажите дату рождения в формате ДД.ММ.ГГГГ'

  /** @type { AgeLimitRule } */
  const ageLimit = val => {
    const now = new Date()
    const selectedDate = parse(val, 'dd.MM.yyyy', new Date())
    const age = differenceInYears(now, selectedDate)

    if (
      Number.isNaN(age)
        || age > MAX_PATIENT_AGE
        || age < 0
        || (now < selectedDate)
    ) {
      return 'Проверьте дату рождения'
    }

    const rangeAgeLimitsFiltered = rangeAgeLimits.filter(i => i)
    const isAllFailedValidation = window.FEATURE_FLAGS.pd_fix_age_appointment
      ? rangeAgeLimitsFiltered.every(({ min, max }) => (
        (min && age < min)
        || (max && age >= max)
      ))
      : rangeAgeLimitsFiltered.every(({ min, max }) => (
        (min && age < min)
        || (max && age > max)
      ))

    if (isAllFailedValidation && rangeAgeLimitsFiltered.length) {
      const { min, max } = rangeAgeLimits[0]

      if (min && age < min) {
        const minYearsDeclension = declensionByNum(min, YEAR_CASES.genitive)

        return isServices
          ? `Услуга только для пациентов с ${min} ${minYearsDeclension}`
          : `Врач принимает только пациентов с ${min} ${minYearsDeclension}`
      }

      if (window.FEATURE_FLAGS.pd_fix_age_appointment) {
        if (max && age >= max) {
          const maxYearsDeclension = declensionByNum(max, YEAR_CASES.genitive)

          return isServices
            ? `Услуга только для пациентов до ${max} ${maxYearsDeclension}`
            : `Врач принимает только пациентов до ${max} ${maxYearsDeclension}`
        }
      } else if (max && age > max) {
        const maxYearsDeclension = declensionByNum(max, YEAR_CASES.genitive)

        return isServices
          ? `Услуга только для пациентов до ${max} ${maxYearsDeclension}`
          : `Врач принимает только пациентов до ${max} ${maxYearsDeclension}`
      }
    }

    return true
  }

  return { required, ageLimit }
}

export default getBirthDayValidationRulesAllSpecialties
