/**
 * Возвращает форматированную цену
 *
 *
 * @example
 * getFormattedPrice(0)
 * >>>: 'Бесплатно'
 *
 * getFormattedPrice(0, { textForZero: false })
 * >>>: '0 ₽'
 *
 * getFormattedPrice(123, { prefix: 'от' })
 * >>>: 'от 123 ₽'
 *
 * getFormattedPrice('£1,739.12')
 * >>>: '1739.12 ₽'
 *
 * getFormattedPrice('123.321.123')
 * >>>: null
 *
 * getFormattedPrice('')
 * >>>: null
 *
 * getFormattedPrice(123456789, { locales: 'ru-RU' })
 * getFormattedPrice(123456789, { locales: 'en-US' })
 * >>>: '123 456 789 ₽'
 * >>>: '123,456,789 ₽'
 *
 *
 * @param { Number | String } price - цена, которую необходимо отформатировать
 * @param { Object } options
 * @param { String } [options.locales] - локаль, на основе которой будет форматироваться цена (от неё зависит разделитель для целой и дробной части и разделитель разрядов, см. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#using_locales)
 * @param { String } [options.textForZero] - текст, который будет возвращаться в качестве результат когда price === 0
 * @param { String } [options.prefix] - префикс, который будет добавлен к возвращаемому результату
 * @param { String } [options.postfix] - постфикс, который будет добавлен к возвращаемому результату
 *
 * @return { String | null }
 * */

const defaultOptions = {
  locales: 'ru-RU',
  textForZero: 'Бесплатно',
  prefix: '', // от
  postfix: window.CURRENCY_DATA.symbol,
}

export default function getFormattedPrice(
  price: number,
  {
    locales = defaultOptions.locales,
    textForZero = defaultOptions.textForZero,
    prefix = defaultOptions.prefix,
    postfix = defaultOptions.postfix,
  }: {
    locales?: string
    textForZero?: string
    prefix?: string
    postfix?: string
  } = defaultOptions,
) {
  const priceOnlyNumbers = String(price).replace(/[^\d.]*/g, '') // £1,739.12 -> 1739.12
  const priceAsNumber = priceOnlyNumbers.length ? Number(priceOnlyNumbers) : null
  const isPriceValid = !Number.isNaN(priceAsNumber) && typeof priceAsNumber === 'number'

  if (priceAsNumber === 0 && textForZero && !prefix) {
    return textForZero
  }

  if (isPriceValid) {
    const priceLocaleString = priceAsNumber.toLocaleString(locales)

    return `${prefix ? `${prefix} ` : ''}${priceLocaleString}${postfix ? ` ${postfix}` : ''}`
  }

  return null
}
