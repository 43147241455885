import { TYPES_PAYMENT } from 'components/common/Club/constants'

export * from './constantsFamilyProfiles'

export const MOUNT_ID_APPOINTMENT = 'appointment-page'

export const USER_ACTION_AFTER_AUTH = {
  sendAppointmentForm: 'sendAppointmentForm',
}

export const APPOINTMENT_ERRORS = {
  defaultTryAgain: {
    title: 'Не получилось записаться',
    subtitle: 'Обновите страницу и попробуйте ещё раз.',
    buttonState: {
      get: 'reload',
      post: 'closeAndReload',
    },
  },
  appointmentNotAvailable: {
    title: 'По выбранным параметрам уже не получится записаться',
    subtitle: `Подобрали для вас другие варианты в этой же клинике.
            Проверьте, подходят ли вам врач и оборудование.`,
    buttonState: {
      get: 'understandWithReload',
      post: 'understandWithReload',
    },
  },
  notNetwork: {
    title: 'Нет соединения с интернетом',
    subtitle: 'Проверьте соединение и попробуйте снова.',
    buttonState: {
      get: 'understandWithReload',
      post: 'understandWithReload',
    },
  },
  appointmentDateNotAvailable: {
    subtitle: 'Нет доступного времени для\u00A0записи',
  },
  notAuthorization: {
    title: 'Войдите в свой профиль, чтобы продолжить',
    buttonState: {
      get: 'loginHowPatient',
      post: 'loginHowPatient',
    },
  },
  appointmentPreliminaryConsultationNotAvailable: {
    title: 'По выбранным параметрам уже не получится записаться',
    subtitle: 'Подобрали для вас другие варианты в этой же клинике.',
    buttonState: {
      get: 'understand',
      post: 'understand',
    },
  },
  slotsIsOver: {
    title: 'Закончилось время в\u00A0расписании',
    subtitle: 'Можно выбрать другую клинику.',
    buttonState: {
      get: 'selectOtherLpu',
      post: 'selectOtherLpu',
    },
  },
}

export const APPOINTMENT_SUCCESSES = {
  defaultSuccess: {
    title: 'Вы записаны на приём',
    subtitle: 'Мы отправили вам СМС с деталями записи.',
  },
}

export const APPOINTMENT_TYPE_NAMES = {
  direct: 'direct',
  express: 'express',
}

export const APPOINTMENT_TYPE_LABELS = {
  [APPOINTMENT_TYPE_NAMES.direct]: 'Онлайн-запись',
  [APPOINTMENT_TYPE_NAMES.express]: 'Экспресс-запись',
}

export const PAYMENT_TYPES = {
  club: TYPES_PAYMENT.online,
  lpu: TYPES_PAYMENT.offline,
}

export const NEW_APPOINTMENT_URLS = {
  service: '/ajax/appointment/service/new/',
  doctor: '/ajax/appointment/doctor/new/',
}

export const APPOINTMENT_PLACE_TYPES = {
  home: 'na_dom',
  online: 'online',
  telemed: 'telemed',
  club: 'club',
}

export const EXPRESS_SOCKET_PATH = `wss://${window.location.hostname}/ws/app_express?subscribe-user`

const FORM_CHECKBOXES_DATA = {
  isMissingStimulant: {
    label: 'У меня не установлен кардио-\n или нейростимулятор',
    dataQA: 'appointment_form_has_stimulant_checkbox',
  },
  isMissingMetal: {
    label: 'В моем организме нет\n предметов из металла',
    dataQA: 'appointment_form_has_metal_checkbox',
  },
}

export const FORM_CHECKBOXES_OF_CLASSES = {
  mrt: [FORM_CHECKBOXES_DATA.isMissingStimulant, FORM_CHECKBOXES_DATA.isMissingMetal],
}

/**
 * Перед редиректом в МедТочку необходимо отображать текст в прелоадере минимум 4 сек. (*решение дизайнера)
 * */
export const PRELOADER_DELAY_FOR_TELEMED_MS = 4000

export const MEDTOCHKA_APP_URL = 'https://redirect.appmetrica.yandex.com/serve/316998695725886786'

export const AGE_OF_MAJORITY = 18

export const DELAY_UNTIL_SKELETONS_DISAPPEAR = 300

export const SUCCESS_SCREEN_INFO_STEPS = [
  'Заполните анкету пациента',
  'Подготовьте вопросы к врачу',
  'Если нужно, перенесите дату записи',
]
