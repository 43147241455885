/**
 * @author Кирилл Шабанов (@shabanov)
 */

import Vue from 'vue'
import { PiniaVuePlugin } from 'pinia'

Vue.config.devtools = process.env.NODE_ENV === 'development'

Vue.use(PiniaVuePlugin)

export default Vue
